import type { AppProps } from "next/app";
import { AuthProvider } from "../lib/auth/AuthProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppChakraProvider } from "../components/global-styles/AppChakraProvider";
import { AuthGuard } from "../lib/auth/AuthGuard";
import "../styles/globalStyles.css";
import Head from "next/head";
import { SidebarLayout } from "../components/sidebar/SidebarLayout";
import { DialogProvider } from "../components/modals/hooks/DialogContext";
import { useRouter } from "next/router";
import AppWrapper from "../components/AppWrapper";
import HubSpotBox from "../components/HubSpotBot";
import "ts-replace-all";

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const AnyComponent = Component as any; // this is to fix a shitty typescript error

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/favicon.ico" />

        <meta charSet="utf-8" />
        <title>Tether</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <AppChakraProvider>
          <AuthProvider>
            <AuthGuard>
              <DialogProvider>
                {router.pathname.includes("/ext/") ||
                router.pathname.includes("/company-settings") ||
                router.pathname.includes("/customer-order") ? (
                  <AnyComponent {...pageProps} />
                ) : (
                  <SidebarLayout>
                    <AppWrapper>
                      <AnyComponent {...pageProps} />
                    </AppWrapper>
                  </SidebarLayout>
                )}
              </DialogProvider>
            </AuthGuard>
          </AuthProvider>
        </AppChakraProvider>
        {/*<ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />*/}
      </QueryClientProvider>
      <HubSpotBox />
    </>
  );
}
export default MyApp;
